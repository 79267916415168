<template>
  <div>
    <v-navigation-drawer v-model="drawer" app class="hidden-md-and-up">
      <v-list dense>
        <div class="font-weight-medium secondary white--text font-weight-medium text-h6 text-center">
          VENDAS ONLINE
        </div>
        <v-divider color="secondary" />

        <v-expansion-panels accordion flat class="mobile-expansion-panels">
          <v-expansion-panel>
            <v-expansion-panel-header style="margin-top: -20px;margin-bottom: -20px;" dense>
              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-list-item-icon>
                <v-list-item-title> CATEGORIAS({{ categorias.length }})</v-list-item-title>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-0 px-0">
              <div>
                <div v-for="(n, index) in categorias" :key="index" class="me-2">
                  <v-list-item link :to="{ name: 'ProdutosPorCategoria', params: { uuid: n.uuid } }" @click.native="reloadPage">
                    <v-list-item-icon>
                      <v-icon>{{ n.icon ? n.icon : "mdi-home" }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> {{ n.designacao }}</v-list-item-title>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider color="secondary" />

        <v-list-item link outlined :to="{name:'Home'}">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>INÍCIO</v-list-item-title>
        </v-list-item>

        <v-list-item outlined link :to="{name:'CarrinhoResumo'}">
          <v-list-item-icon>
            <v-icon>mdi-cart </v-icon>
          </v-list-item-icon>
          <v-list-item-title>MINHAS COMPRAS </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="userLogado" outlined link :to="{name:'ClienteDashboard'}">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>ÁREA DE CLIENTE</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{name:'QuemSomos'}">
          <v-list-item-icon>
            <v-icon> mdi-information-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>QUEM SOMOS</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!userLogado" outlined link :to="{name:'PortalLogin'}">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-title> ÁREA DE CLIENTE </v-list-item-title>
        </v-list-item>

        <v-divider color="secondary" />

        <v-list-item link :to="{name:'FAQS'}" title="Perguntas Frequentes">
          <v-list-item-icon>
            <v-icon> mdi-frequently-asked-questions </v-icon>
          </v-list-item-icon>
          <v-list-item-title>PERGUNTAS FREQUENTES</v-list-item-title>
        </v-list-item>

        <v-list-item link :to="{name:'ContactosIndex'}">
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-title>SUPORTE 24/24 H</v-list-item-title>
        </v-list-item>
        <v-divider color="secondary" />

        <v-list-item v-if="userLogado" link @click="showDialogTerminarSessao()">
          <v-list-item-icon>
            <v-icon color="red">
              mdi-power
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title> TERMINAR SESSÃO</v-list-item-title>
        </v-list-item>

        <v-divider color="secondary" />
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark dense class="secondary hidden-md-and-up" elevation="0">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="font-weight-bold">
        MUTUE VENDAS ONLINE
        <!-- <router-link to="/">
          <v-avatar style="margin-left: -30px;" width="65" title="Mutue Vendas Online" height="50" tile>
            <img title="Mutue Vendas Online" src="@/assets/images/MVO.png">
          </v-avatar>
        </router-link>  -->
        <v-btn title="Apoio ao cliente pelo Whatsapp:9346 60 003" target="_blank" href="https://api.whatsapp.com/send?1=pt_BR&phone=244934660003&text=Ol%C3%A1!%20Tudo%20bem?%20Gostaria%20de%20saber%20como%20funciona%20o%20vosso%20Aplicativo." class="me-2 white--text" elevation="0" x-small fab color="primary">
          <v-icon medium size="24px" color="white">
            mdi-whatsapp
          </v-icon>
        </v-btn>
      </v-toolbar-title>
    </v-app-bar>

    <v-dialog v-model="dialogTerminarSessao" name="dialog-terminar-sessao" max-width="300">
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="secondary" class="mt-2" size="60">
            mdi-information-outline
          </v-icon>
          <h5 class="mt-2">
            Pretende realmente terminar sessão?
          </h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="dialogTerminarSessao = false">
            Fechar
          </v-btn>
          <v-btn color="secondary darken-1" text @click="logoutCliente()">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLogoutSucesso" name="dialog-logout-sucesso" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50">
            mdi-check-circle
          </v-icon>
          <h6 class="mt-2">
            Sessão terminada com sucesso!
          </h6>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { LOGIN_URL } from '@/configs/api'
  import axios from 'axios'

  export default {
    data: () => ({
      dialogTerminarSessao: false,
      dialogLogoutSucesso: false,
      categorias: [],
      userLogado: false,
      value: 1,
      drawer: false,
    }),
    computed: {
    },
    mounted () {
      this.getAllCategories()
      if (localStorage.getItem('TOKEN')) {
        // alert("TRUE");
        this.userLogado = true
      }
    },
    methods: {
      showDialogTerminarSessao () {
        this.dialogTerminarSessao = true
      },
      reloadPage () {
        setTimeout(() => {
          window.location.reload()
        }, 120)
      },
      logoutCliente () {
        this.loading = true
        this.dialogTerminarSessao = false
        const url = `${LOGIN_URL}/user/logout`
        axios
          .post(url)
          .then((response) => {
            this.dialogLogoutSucesso = true
            // console.log(response.data);
            if (
              localStorage.getItem('TOKEN') ||
              localStorage.getItem('TOKEN') ||
              sessionStorage.removeItem('USER_LOGADO') ||
              localStorage.removeItem('userLogado')
            ) {
              sessionStorage.removeItem('TOKEN')
              sessionStorage.removeItem('USER_LOGADO')
              localStorage.removeItem('userLogado')
              localStorage.removeItem('TOKEN')
            }
            setTimeout(() => {
              this.$router.push({
                name: '/',
              })
              this.loading = false
              this.dialogLogoutSucesso = false
            }, 3000)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
          .catch((error) => {
            if (
              localStorage.getItem('TOKEN') ||
              localStorage.getItem('TOKEN') ||
              sessionStorage.removeItem('USER_LOGADO') ||
              localStorage.removeItem('userLogado')
            ) {
              sessionStorage.removeItem('TOKEN')
              sessionStorage.removeItem('USER_LOGADO')
              localStorage.removeItem('userLogado')
              localStorage.removeItem('TOKEN')
              this.dialogLogoutSucesso = true
            }

            setTimeout(() => {
              this.$router.push({
                name: '/',
              })
              this.loading = false
              this.dialogLogoutSucesso = false
            }, 4500)
            setTimeout(() => {
              window.location.reload()
            }, 5000)
          })
      },

      getAllCategories () {
        axios
          .get(LOGIN_URL + '/listarCategorias')
          .then((response) => {
            this.categorias = response.data
          // alert(JSON.stringify(this.categories))
          })
          .catch((error) => {})
      },
    },
  }
</script>
<style lang="scss">
.mobile-navigation-bottom {
  bottom: 0;
  z-index: 100 !important;
}
</style>
